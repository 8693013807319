import Head from '/components/Head';
import MainLayout from '/layouts/MainLayout';
import NickGetsLashed from '/components/NickGetsLashed';

const NotFound404 = () => {
	return (
		<>
			<Head
				description="Shinesty - 404 Not Found"
				descriptionOg="Shinesty - 404 Not Found"
				title="Shinesty - 404 Not Found">
				<meta name="robots" content="noindex" />
			</Head>

			<NickGetsLashed title="404" />
		</>
	);
};

NotFound404.getLayout = (page) => <MainLayout>{page}</MainLayout>;

export default NotFound404;
